import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import Item from './item'
import { Section, ScrollContainer, List } from './servicesNav.css'

const ServicesNav = ({ services }) => (
  <Section>
    <Fade bottom delay={150} duration={1000} distance="20px">
      <ScrollContainer>
        <List>
          {services.map((service, i) => (
            <Item {...service} key={i} />
          ))}
        </List>
      </ScrollContainer>
    </Fade>
  </Section>
)

ServicesNav.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ServicesNav
