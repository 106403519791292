import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './headingWithIcon.css'

const HeadingWithIcon = (props) => (
  <Container>
    <img src={props.imageSrc} alt={props.title} />
    <h2>{props.title}</h2>
  </Container>
)

HeadingWithIcon.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
}

export default HeadingWithIcon
