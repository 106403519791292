import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: var(--sm);

  h2 {
    font-size: var(--copyMedium);
    line-height: 40px;
  }

  img {
    margin-right: var(--sm);
  }
`
