import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Section = styled.section`
  max-width: 1128px;
  margin: 0 auto var(--lg);

  ${media.tablet`

  `}
`
export const ScrollContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }
`
export const List = styled.ul`
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 0 auto;
`
