import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'
import { ListItem, Img, Span } from './item.css'

const Item = ({ title, link, image }) => (
  <ListItem>
    <Link to={link} smooth={true} duration={500} offset={1}>
      <Img src={image.publicURL} alt={title} />
      <Span>{title}</Span>
    </Link>
  </ListItem>
)

Item.propTypes = {
  title: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export default Item
