import styled from 'styled-components'
import media from 'constants/breakpoints'

export const Container = styled.div`
  margin-bottom: var(--lg);
  border-top: 1px solid #eee;
  padding-top: var(--lg);

  ${media.desktop`
    padding-top: var(--xl);
  `}

  :first-child {
    border-top: none;
    padding-top: 0;
  }
`
