import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import Layout from 'components/layout'
import HeroBanner from 'components/heroBanner'
import Intro from 'components/intro'
import ServicesNav from 'components/servicesNav'
import CopyBlock from 'components/copyBlock'
import CopySplit from 'components/copySplit'
import PartnerCarousel from 'components/partnerCarousel'
import Awards from 'components/awards'
import { graphql } from 'gatsby'

import ServiceContainer from 'components/serviceContainer'
import HeadingWithIcon from 'components/headingWithIcon'

import exchange from 'images/cloud/exchange-email-primary.svg'
import hostedDesktop from 'images/cloud/hosted-desktop-primary.svg'
import hostedServers from 'images/cloud/hosted-servers-primary.svg'
import citrix from 'images/cloud/citrix-primary.svg'
import cloudBackup from 'images/cloud/cloud-backup-primary.svg'
import office365 from 'images/cloud/office365-primary.svg'
import iaasPaas from 'images/cloud/iaas-paas-primary.svg'

const CloudPage = ({ data, location }) => (
  <Layout location={location}>
    <Helmet>
      <title>{data.cloudJson.title}</title>
      <meta name="description" content={data.cloudJson.description} />
    </Helmet>
    <HeroBanner hero={data.cloudJson.hero} highlightColor="orange" />
    <Intro intro={data.cloudJson.intro} />
    <ServicesNav services={data.cloudJson.services} />
    <CopyBlock bgTint>
      <ServiceContainer id="exchange">
        <CopySplit>
          <HeadingWithIcon title="Exchange Email" imageSrc={exchange} />
          <p>
            Our hosted exchange email brings you maximum security, with email scanning, filtering and attachment
            sandboxing. There’s no need to worry about spam emails, threats or viruses – your account is in good hands!
          </p>
        </CopySplit>
        <CopySplit>
          <h3>Top features</h3>
          <div>
            <h4>Data Sovereignty</h4>
            <p>Guaranteed, as all our servers are hosted in the UK</p>
            <h4>Superior Storage, Archiving and Rollback</h4>
            <p>
              Annual or indefinite archiving, 100GB inbox space, 40MB attachment size, custom domain names and secure
              cloud systems
            </p>
            <h4>Spam and Virus Firewall</h4>
            <p>Anti-virus software prevents any malware from reaching you or unwanted mails in your inbox</p>
            <h4>24/7 Support</h4>
            <p>Any email issues are handled by our UK-based technical team</p>
          </div>
        </CopySplit>
      </ServiceContainer>

      <ServiceContainer id="hosted-desktop">
        <CopySplit>
          <HeadingWithIcon title="Hosted Desktop" imageSrc={hostedDesktop} />
          <p>
            Using Cloud-hosted desktops enables remote working and increases productivity. It’s also the most
            cost-effective way of reaching important files from anywhere in the world – so it’s something your business
            can’t afford to ignore! Our hosted desktop offers a secure and simple solution.{' '}
          </p>
        </CopySplit>
        <CopySplit>
          <h3>Top features</h3>
          <div>
            <h4>Accessibility</h4>
            <p>Access files and apps from any location at the click of a button</p>
            <h4>Scalability</h4>
            <p>Add or remove desktops as and when you need to grow efficiently</p>
            <h4>Data Security</h4>
            <p>
              All data is stored securely in the Cloud from ISO027001 UK-based data centres – protected by 2048-bit
              encryption and backups for world-class security
            </p>
            <h4>24/7 Support</h4>
            <p>Any hosted desktop issues are handled by our technical team in the UK</p>
          </div>
        </CopySplit>
      </ServiceContainer>

      <ServiceContainer id="hosted-servers">
        <CopySplit>
          <HeadingWithIcon title="Hosted Servers" imageSrc={hostedServers} />
          <p>We’ll customise a server package to suit your business’ needs and to help you achieve your goals.</p>
        </CopySplit>
        <CopySplit>
          <h3>Top features</h3>
          <div>
            <h4>Secure Data Centres</h4>
            <p>
              Servers are hosted across several secure UK-based servers – with the best in physical and virtual security
            </p>
            <h4>Affordability</h4>
            <p>The most cost-effective approach, especially useful if you’re a small business</p>
            <h4>Ultra-Secure Hosting</h4>
            <p>Secure partitioning keeps data safe and secure</p>
            <h4>Cloud, VPA and Dedicated Servers</h4>
            <p>Flexible, yet personalised for your business</p>
          </div>
        </CopySplit>
      </ServiceContainer>

      <ServiceContainer id="citrix">
        <CopySplit>
          <HeadingWithIcon title="Citrix Desktop" imageSrc={citrix} />
          <p>
            We offer both Microsoft RDP and Citrix, to give you the best solution for your business. Let’s face it –
            complex software can mean extra costs and hassle! Luckily, Citrix is a simple software that doesn’t require
            extensive training courses. And we’ll support you all the way – from setting it up to getting used to it –
            to make the switch even easier.
          </p>
        </CopySplit>
        <CopySplit>
          <h3>Top features</h3>
          <div>
            <h4>Tailored Citrix Solutions</h4>
            <p>Each one created to meet your individual needs</p>
            <h4>Flexible Accounts</h4>
            <p>Add or remove desktops easily for quick scalability</p>
            <h4>World-Class Security</h4>
            <p>Those UK-based data centres we mentioned will keep your data extra secure</p>
            <h4>24/7 UK Support</h4>
            <p>We’ll handle any Citrix-related issues for you, should anything arise</p>
          </div>
        </CopySplit>
      </ServiceContainer>

      <ServiceContainer id="cloud-backup">
        <CopySplit>
          <HeadingWithIcon title="Cloud Backup" imageSrc={cloudBackup} />
          <p>
            Our online Cloud backup service provides automatic backups. So you can rest assured in the knowledge that
            all your information and data is stored safely. Phew.
          </p>
        </CopySplit>
        <CopySplit>
          <h3>Top features</h3>
          <div>
            <h4>Enhanced Protection</h4>
            <p>This service offers an extra layer of protection against ransomware</p>
            <h4>Redundancy</h4>
            <p>Physical server gone offline? You’ll go to fail-safe mode, over to a Cloud server</p>
            <h4>Data Recovery</h4>
            <p>A process that’s thorough, quick and easy</p>
            <h4>Timely Reports</h4>
            <p>Access up-to-date backup status reports when you need them</p>
          </div>
        </CopySplit>
      </ServiceContainer>

      <ServiceContainer id="office365">
        <CopySplit>
          <HeadingWithIcon title="Office 365" imageSrc={office365} />
          <p>
            Enjoy this integrated experience of services to help you grow your business. Apps including Word, Excel and
            PowerPoint are updated monthly with the latest features and security updates. While Cloud-powered email lets
            you reach customers and co-workers – wherever your work takes you.
          </p>
        </CopySplit>
        <CopySplit>
          <h3>Top features</h3>
          <div>
            <h4>Mobile Office</h4>
            <p>Work from anywhere with an internet connection</p>
            <h4>Easy Collaboration</h4>
            <p>
              Staff can simultaneously contribute to one document simultaneously (for easy editing or presenting), while
              sharing files is quicker than sending as attachments
            </p>
            <h4>Latest Versions</h4>
            <p>Programmes and apps can be updated regularly for enhanced security and features</p>
            <h4>Top Security</h4>
            <p>
              Features like encrypted email, data loss prevention, mobile device management and Advanced Threat
              Analytics (ATA) offer more security, convenience and peace of mind
            </p>
          </div>
        </CopySplit>
      </ServiceContainer>

      <ServiceContainer id="iaas-paas">
        <CopySplit>
          <HeadingWithIcon title="IaaS/PaaS" imageSrc={iaasPaas} />
          <p>
            Want a fully managed and supported infrastructure – Infrastructure as a Service (IaaS)? Or would you prefer
            to hire your own dedicated infrastructure – Platform as a Service (PaaS)? Whatever your needs, we’ve got a
            solution to fit. Our servers are in a Tier 3 data centre with top security and an ultra-fast internet speed
            of 100,000 Mb/S. That’s less complexity and more scalability…at a price you can afford!
          </p>
        </CopySplit>
        <CopySplit>
          <h3>Top features</h3>
          <div>
            <h4>Hands Free</h4>
            <p>We take care of the infrastructure – so you don’t have to</p>
            <h4>Increased Speed</h4>
            <p>That’s fully load-balanced and maintained</p>
            <h4>More Control</h4>
            <p>You can control your operating system, apps and storage</p>
          </div>
        </CopySplit>
      </ServiceContainer>
    </CopyBlock>

    <PartnerCarousel />
    <Awards />
  </Layout>
)

CloudPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default CloudPage

export const query = graphql`
  query CloudPageQuery {
    cloudJson {
      title
      description
      hero {
        heading
        image {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
      intro {
        heading
        copy
      }
      services {
        title
        link
        image {
          publicURL
        }
      }
    }
  }
`
