import React from 'react'
import PropTypes from 'prop-types'
import { Container } from './serviceContainer.css'

const ServiceContainer = (props) => (
  <Container id={props.id}>
    {props.children}
  </Container>
)

ServiceContainer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
}

export default ServiceContainer
