import styled from 'styled-components'

export const ListItem = styled.li`
  background-image: var(--orangeGrad);
  color: var(--white);
  margin-right: var(--xs);

  a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-width: 128px;
    min-height: 128px;
    padding: var(--md) var(--sm);
    text-decoration: none;
  }

  :first-child {
    margin-left: var(--md);
  }

  :last-child {
    margin-right: var(--md);
  }
`
export const Img = styled.img`
  margin-bottom: var(--xs);
  width: 36px;
  height: 36px;
`
export const Span = styled.span`
  font-family: var(--headings);
  font-size: var(--copySmall);
  margin-bottom: var(--xs);
`
